import {faPlus, faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons';
import {useIsFocused} from '@react-navigation/native';
import {array} from 'prop-types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import toast from 'react-hot-toast';
import {Text, ActivityIndicator, View} from 'react-native';

import FaIcon from './FaIcon';
import Portal from './Portal.web';
import {useAdminData} from '../context/AdminContext';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';

export default function UploadZone({
  uploadInfo,
  buttonMode = false,
  style: overrides,
  children,
}) {
  const {adminData} = useAdminData();
  const [inProgress, setInProgress] = useState(false);
  const [allResults, setAllResults] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const {style, values} = useTheme(getThemedStyles);
  const {currentEvent, currentPack, createPack, uploadFiles} = useAssets();
  const {op, operatorData} = useOperator();
  const [mouseHovering, setMouseHovering] = useState(false);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const appRoot = useRef(document.getElementById('root'));
  const isLoggedIn = op;
  const isFocused = useIsFocused();

  // *** Logic for the upload button, dropzone and upload interfaces ***

  const showDropzone = useCallback((e) => {
    appRoot.current.removeEventListener('dragenter', showDropzone);
    setMouseHovering(true);
    setDropzoneActive(true);
  }, []);

  const hideDropzone = useCallback(() => {
    setMouseHovering(false);
    setDropzoneActive(false);
    appRoot.current.addEventListener('dragenter', showDropzone);
  }, [showDropzone]);

  // Attach show dropzone listeners
  useEffect(() => {
    function onKey(e) {
      if (e.key === 'Escape') {
        hideDropzone();
      }
    }

    window.addEventListener('keydown', onKey);
    document.addEventListener('mousedown', hideDropzone);
    return () => {
      window.removeEventListener('keydown', onKey);
      document.removeEventListener('mousedown', hideDropzone);
    };
  }, [hideDropzone]);

  // Attach hide dropzone listeners
  useEffect(() => {
    const {current: appRootNode} = appRoot;
    appRootNode.addEventListener('dragenter', showDropzone);
    return () => appRootNode.removeEventListener('dragenter', showDropzone);
  }, [showDropzone]);

  // *** Upload functionality ***
  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    onDragLeave: hideDropzone,
    accept:
      'image/jpeg, image/jpg, image/png, video/mp4, video/quicktime, video/x-msvideo, video/mpeg, video/x-ms-wmv',
  });

  async function upload(contentToUpload, videoBacklog) {
    //Prep the content into an array of chunks so that uploads will be easier to process.
    //The functions return promises, allowing them to be run any time.

    // Get a flat array of functions which upload batches of content
    const imageUploadStarters = contentToUpload
      .filter((c) => c.content.length > 0)
      .map((uploadContent) => {
        const batchUploaders = [];
        const batchSize = 1;

        for (let i = 0; i < uploadContent.content.length; i += batchSize) {
          const batch = uploadContent.content.slice(i, i + batchSize);
          async function batchUploader() {
            const results = await Promise.allSettled(
              uploadFiles(
                batch,
                currentEvent?.name,
                uploadContent.packName,
                uploadInfo,
              ),
            );
            return {
              content: batch,
              results,
            };
          }

          batchUploaders.push(batchUploader);
        }

        return batchUploaders;
      })
      .flat();

    const videoUploadStarters = videoBacklog
      .filter((c) => c.content.length > 0)
      .map((uploadContent) => {
        async function uploader() {
          const results = await Promise.allSettled(
            uploadFiles(
              uploadContent.content,
              currentEvent?.name,
              uploadContent.packName,
              uploadInfo,
            ),
          );
          return {
            content: uploadContent.content,
            results,
          };
        }
        return uploader;
      });

    const uploadStarters = [...imageUploadStarters, ...videoUploadStarters];

    for (const starter of uploadStarters) {
      const {content, results} = await starter();

      content.forEach((c, i) => {
        const thisResult = results[i];
        const uploadId = c.path + c.dropTime;
        uploadInfo.current[uploadId].status = thisResult.status;
      });

      setAllResults([...allResults, ...results]);
    }
  }

  onDrop.proptypes = {
    files: array.isRequired, //files is an array of file objects.
  };
  function validateUploads(files) {
    const uploadInfoArr = Object.keys(uploadInfo.current).map((key) => [
      key,
      uploadInfo.current[key],
    ]);

    const validFiles = files.filter((f) => {
      console.log('Files: ', f);

      const isMacOsHidden = f?.name?.startsWith?.('._'); // Fuck macOS
      const isImage = f?.type?.includes?.('image');
      const isVideo = f?.type?.includes?.('video');
      const isValidType = (isImage || isVideo) && !isMacOsHidden;
      if (!isValidType) {
        return false;
      }

      // Validate filesize
      if (f.size > 500000000) {
        toast.error(`File: "${f.name}" is too large. (500MB limit)`);
        return false;
      }

      // Reject concurrent duplicates
      const fileIsDuplicate = uploadInfoArr.find((obj) => {
        if (
          obj[1].path === f.path ||
          obj[1].path === `${currentPack?.name}/${f.path}`
        ) {
          return true;
        }
      });
      if (fileIsDuplicate) {
        toast.error(`File: "${f.name}" is a duplicate.`);
        return false;
      }

      return true;
    });

    // Reject if more than maxUploadQueueSize files
    const activeUploadCount = uploadInfoArr.filter(
      (obj) => obj[1].status === 'pending',
    ).length;
    const maxQueueSize = adminData?.maxUploadQueueSize || 100; // fallback to 100 if not set
    if (activeUploadCount > maxQueueSize || validFiles?.length > maxQueueSize) {
      toast.error(
        `Too many files. Only ${maxQueueSize} files can upload at once.`,
      );
      return;
    }

    if (validFiles?.length <= 0) {
      toast.error('No valid files to upload.');
      return;
    }

    const bytesLimit = operatorData?.storage?.bytesLimit;
    const bytesUsed = operatorData?.storage?.bytesUsed;
    const bytesRemaining = bytesLimit - bytesUsed;
    const bytesToUpload = validFiles.reduce((acc, file) => acc + file.size, 0);

    if (bytesToUpload > bytesRemaining) {
      toast.error('Not enough storage space.');
      return;
    }

    return validFiles;
  }

  /**
   *
   * @param {Object[]} files
   */
  function onDrop(files) {
    hideDropzone();

    const validFiles = validateUploads(files);
    if (!validFiles) {
      return;
    }

    validFiles.forEach((file) => {
      file.dropTime = Date.now();
    });

    //Create packs for each folder containing a file.
    const packsToCreate = [];
    let filesAtRoot = false;

    validFiles.forEach((file) => {
      if (file.path.includes('/')) {
        const folderArr = file.path.split('/');
        const folder = folderArr[folderArr.length - 2];
        if (!packsToCreate.includes(folder)) {
          packsToCreate.push(folder);
        }
      } else {
        filesAtRoot = true;
      }
    });

    // prevent uploading loose assets without a pack.
    if (!currentPack && filesAtRoot) {
      toast.error('Create a pack before uploading individual files.');
      setAllFiles([]);
      return;
    }

    const newFiles = [];
    validFiles.forEach((file) => {
      if (!allFiles.includes(file)) {
        newFiles.push(file);
      }
    });
    setAllFiles([...allFiles, ...newFiles]);

    const createPackPromises = [];
    if (packsToCreate.length > 0) {
      packsToCreate.forEach((folder) =>
        createPackPromises.push(createPack(folder)),
      );
    }

    //Wait for packs to be created, then upload.
    Promise.allSettled(createPackPromises).then((results) => {
      const uploadPacks = results
        .map((result) => result.value)
        .concat(currentPack) // Add loose assets to current pack
        .filter(Boolean);
      //Create a 2D array of all files to upload, sorted by pack.
      const contentToUpload = uploadPacks.map(({name}) => ({
        packName: name,
        content: [],
      }));

      const videoBacklog = [];

      validFiles.forEach((file) => {
        const isInFolder = file.path.includes('/');
        const parentPack = uploadPacks.find((p) => file.path.includes(p.name));
        let folder = parentPack?.name || currentPack?.name;
        let targetIndex = contentToUpload.length - 1;
        if (isInFolder) {
          folder = file.path.split('/').at(-2);
          targetIndex = contentToUpload.findIndex(
            (item) => item.packName === folder,
          );
        }
        if (file.type.includes('video')) {
          videoBacklog.push({packName: folder, content: [file]});
        } else {
          contentToUpload[targetIndex].content.push(file);
        }
      });
      setInProgress(true);

      upload(contentToUpload, videoBacklog)
        .then(() => setInProgress(false))
        .catch((err) => {
          toast.error(err + 'Connection failed.');
          console.error(err);
        });
    });
  }

  //Warn the users if they try to leave the page while actively uploading
  useEffect(() => {
    function warnLeave(event) {
      event.preventDefault();
      event.returnValue = '';
    }

    if (inProgress) {
      addEventListener('beforeunload', warnLeave);
      return () => {
        removeEventListener('beforeunload', warnLeave);
      };
    }
  }, [inProgress]);

  const shouldHide = !isFocused || (buttonMode && !isLoggedIn);
  if (shouldHide) {
    return null;
  }
  if (buttonMode) {
    return (
      //This can't be an EtherButton. It needs to be a clickable View.
      <View role="button" style={overrides} {...getRootProps()}>
        <input {...getInputProps()} />
        {children}
      </View>
    );
  }
  return (
    <Portal className="dropzone-container">
      <div
        style={dropzoneActive ? style.uploadZone : style.hidden}
        {...getRootProps()}
      >
        {mouseHovering ? (
          <View style={style.dropzoneCenter}>
            <FaIcon icon={faCloudUploadAlt} color={values.FIRST} size={60} />
            <Text style={style.pressESC}>
              {currentEvent?.name} - {currentPack?.name}
            </Text>
            <Text style={style.uploadText}>Drop to upload asset(s)</Text>
            <Text style={style.pressESC}>or press ESC to close.</Text>
          </View>
        ) : inProgress ? (
          <ActivityIndicator size="large" color={values.FIRST} />
        ) : (
          <FaIcon icon={faPlus} color={values.BGSECOND} size={40} />
        )}
      </div>
    </Portal>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  buttonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.legal,
    color: theme.LIGHT,
    marginLeft: 5,
  },
  buttonTextContainer: {
    overflow: 'hidden',
  },
  buttonTextHidden: {
    display: 'none',
  },
  dropzoneCenter: {
    backgroundColor: 'rgba(242,242,242,0.9)',
    borderWidth: 4,
    borderRadius: 15,
    borderStyle: 'dashed',
    borderColor: theme.FIRST,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    inset: '10%',
    transitionDelay: '50ms',
    transitionDuration: '150ms',
    pointerEvents: 'none',
  },
  hidden: {
    display: 'none',
  },
  plusHover: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 20,
    height: 40,
    width: 150,
    alignSelf: 'flex-end',
    right: 20,
    marginRight: 10,
    paddingLeft: 5,
    transition: '0.5s, transform 0.5s',
  },
  plusNoHover: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.SECOND,
    borderColor: theme.FIRST,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: '50%',
    height: 40,
    width: 40,
    alignSelf: 'flex-end',
    right: 20,
    marginRight: 10,
    transition: '0.1s, transform 0.1s',
  },
  pressESC: {
    color: theme.DARK,
    fontFamily: 'NotoSans_Regular',
    fontSize: fontSize.legal,
  },
  uploadButtons: {
    flexDirection: 'row',
    bottom: 15,
    right: 15,
    position: 'fixed',
  },
  uploadText: {
    color: theme.DARK,
    fontFamily: 'NotoSans_Bold',
  },
  uploadZone: {
    backgroundColor: theme.BLACKGROUND,
    position: 'fixed',
    inset: 0,
  },
  uploadzoneHidden: {
    bottom: -50,
  },
  uploadzoneShown: {
    transition: '0.1s, transform 0.1s',
  },
});
