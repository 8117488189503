import {
  faMapMarkerAlt,
  faEllipsisV,
  faTrashAlt,
  faRemove,
  faImage,
  faLock,
  faUnlock,
  faPencilAlt,
  faRocket,
  faStore,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import {transparentize} from 'polished';
import {string, number, shape} from 'prop-types';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {View, Text, TouchableOpacity, Image} from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import EtherDialog from './EtherDialog.js';
import EtherInput from './EtherInput.js';
import EventInfoModal from './EventInfoModal';
import FaIcon from './FaIcon';
import SimpleDropdown from './SimpleDropdown';
import UploadImageModal from './UploadImageModal';
import icon from '../assets/EtherMediaIcon.png';
import useDropdown from '../context/DropdownContext';
import {useOperator} from '../context/OperatorContext';
import {useTheme} from '../context/ThemeContext';
import {useAssets} from '../context/assets-context/AssetsContext';
import {STATUS} from '../utils/common/constants';
import {ellipsify} from '../utils/common/funcs';
import {showHideEvent} from '../utils/common/packs';
import etherFetch from '../utils/ether-fetch/etherFetch';

const modals = {
  access_code: 'access_code',
  delete_event: 'delete_event',
  banner: 'banner',
  remove_access_code: 'remove_access_code',
  kiosk_share: 'kiosk_share',
  store_share: 'store_share',
  rename_event: 'rename_event',
  upload_image: 'upload_image',
};

EventTile.propTypes = {
  event: shape({
    name: string.isRequired,
    location: string.isRequired,
    _id: string.isRequired,
    banner: shape({
      key: string.isRequired,
      url: string.isRequired,
      expiresAt: number.isRequired,
    }),
    images: number,
    videos: number,
    sample: shape({
      key: string,
      url: string,
    }),
    accessCode: string,
  }),
};

export default function EventTile({
  event,
  style: overrides,
  onSelectEvent = () => {},
}) {
  const {renameEvent, dispatch} = useAssets();
  const {operatorData, uploadFile} = useOperator();
  const eventImage = event.banner?.url || event.sample?.url || icon;
  const customURL =
    operatorData?.customization?.customUrl ||
    operatorData?.identification?.orgname;
  const kioskLink = `${window.location.origin}/kiosk/${customURL}?eventId=${event._id}`;
  const storeLink = `${window.location.origin}/store/${customURL}`;
  const [modal, setModal] = useState(null);
  const {dropdownId} = useDropdown();
  const [accessCode, setAccessCode] = useState(event.accessCode || '');
  const [newEventName, setNewEventName] = useState(event.name);
  const [status, setStatus] = useState(STATUS.IDLE);
  const [mouseHover, setMouseHover] = useState(false);
  const {style, values} = useTheme(getThemedStyles);
  const menuEntries = [
    {
      title: event.banner != null ? 'Change Banner' : 'Add Banner',
      faIcon: faImage,
      callback: () => setModal(modals.upload_image),
    },
    {
      title: 'Remove Banner',
      faIcon: faRemove,
      callback: () => setModal(modals.banner),
      show: event.banner != null,
    },
    {
      title:
        event.accessCode != null ? 'Change Access Code' : 'Add Access Code',
      faIcon: faLock,
      callback: () => setModal(modals.access_code),
    },
    {
      title: 'Remove Access Code',
      faIcon: faUnlock,
      callback: () => setModal(modals.remove_access_code),
      show: event.accessCode != null,
    },
    ...(event?.active
      ? [
          {
            title: 'Launch Kiosk',
            faIcon: faRocket,
            callback: () => setModal(modals.kiosk_share),
          },
        ]
      : []),
    ...(event?.active
      ? [
          {
            title: 'Launch Store',
            faIcon: faStore,
            callback: () => setModal(modals.store_share),
          },
        ]
      : []),
    {
      title: event?.active ? 'Hide in Store/Kiosk' : 'Show in Store/Kiosk',
      faIcon: event?.active ? faEyeSlash : faEye,
      callback: () => {
        const {name, _id} = event;
        const newActive = !event?.active;
        showHideEvent(event._id, newActive)
          .then(() => {
            dispatch({
              type: 'event.active',
              value: {eventId: _id, active: newActive},
            });
            toast.success(
              `${name} will now be ${newActive ? 'shown' : 'hidden'} in store`,
            );
          })
          .catch((error) => {
            console.error('Error hiding event:', error);
            toast.error(`Failed to ${newActive ? 'show' : 'hide'} event`);
          });
      },
    },
    {
      title: 'Rename Event',
      faIcon: faPencilAlt,
      callback: () => setModal(modals.rename_event),
    },
    {
      title: 'Delete Event',
      faIcon: faTrashAlt,
      callback: () => setModal(modals.delete_event),
    },
  ];
  const removeBannerControls = [
    {
      text: 'Cancel',
      onPress: () => setModal(null),
    },
    {
      text: 'Remove',
      onPress: () => {
        handleBannerDelete();
        setModal(null);
      },
      status,
      variant: 'primary',
    },
  ];
  const accessCodeControls = [
    {
      text: 'Cancel',
      onPress: () => setModal(null),
    },
    {
      text: 'Add',
      onPress: () => {
        saveAccessCode();
        setModal(null);
      },
      status,
      variant: 'primary',
    },
  ];
  const removeAccessCodeControls = [
    {
      text: 'Cancel',
      onPress: () => setModal(null),
    },
    {
      text: 'Remove',
      onPress: () => {
        removeAccessCode();
        setModal(null);
      },
      status,
      variant: 'primary',
    },
  ];
  const renameEventControls = [
    {
      text: 'Cancel',
      onPress: () => setModal(null),
    },
    {
      text: 'Rename',
      disabled: newEventName === event?.name,
      onPress: () => {
        setStatus(STATUS.BUSY);
        renameEvent(event.name, newEventName).finally(() => {
          setModal(null);
          setStatus(STATUS.IDLE);
        });
      },
      status,
      variant: 'primary',
    },
  ];
  const kioskShareControls = [
    {
      text: 'Open',
      onPress: () => {
        window.open(kioskLink, '_blank');
        setModal(null);
      },
      variant: 'primary',
    },
    {
      text: 'Copy',
      onPress: () => {
        navigator.clipboard.writeText(kioskLink);
        toast.success('Link copied to clipboard');
      },
      variant: 'primary',
    },
  ];

  const storeShareControls = [
    {
      text: 'Open',
      onPress: () => {
        window.location.href = storeLink;
        setModal(null);
      },
      variant: 'primary',
    },
    {
      text: 'Copy',
      onPress: () => {
        navigator.clipboard.writeText(storeLink);
        toast.success('Link copied to clipboard');
      },
      variant: 'primary',
    },
  ];

  function handleBannerUpload(files) {
    setStatus(STATUS.BUSY);
    return uploadFile('/packs/event/banner', files[0], {eventId: event._id})
      .then((axiosRes) => {
        if (!axiosRes?.data?.banner) throw new Error('Invalid upload response');
        const {banner} = axiosRes.data;
        toast.success('Banner upload successful');
        dispatch({
          type: 'event.banner.upload',
          value: {eventId: event._id, banner},
        });
      })
      .catch((err) => {
        console.error('Error uploading banner:', err);
        toast.error('Banner upload failed');
      })
      .finally(() => {
        setModal(null);
        setStatus(STATUS.IDLE);
      });
  }

  function handleBannerDelete() {
    return etherFetch('/packs/event/banner', {
      method: 'DELETE',
      body: {
        eventId: event._id,
        bannerKey: event.banner?.key,
      },
    })
      .then(() => {
        toast.success('Banner removed successfully');
        dispatch({
          type: 'event.banner.remove',
          value: {eventId: event._id},
        });
      })
      .catch(() => {
        toast.error('Banner removal failed');
      })
      .finally(() => {
        setModal(null);
      });
  }

  function saveAccessCode() {
    setStatus(STATUS.BUSY);
    return etherFetch('/packs/event/access-code', {
      method: 'POST',
      body: {
        eventId: event._id,
        accessCode,
      },
    })
      .then(() => {
        dispatch({
          type: 'event.add.access_code',
          value: {eventId: event._id, accessCode},
        });
        toast.success('Access code added');
      })
      .catch(() => {
        toast.error('Access code addition failed');
      })
      .finally(() => {
        setStatus(STATUS.IDLE);
        setModal(null);
      });
  }

  function removeAccessCode() {
    setStatus(STATUS.BUSY);
    return etherFetch('/packs/event/access-code', {
      method: 'DELETE',
      body: {
        eventId: event._id,
      },
    })
      .then(() => {
        dispatch({
          type: 'event.remove.access_code',
          value: {eventId: event._id},
        });
        toast.success('Access code removed successfully');
      })
      .catch(() => {
        toast.error('Access code removal failed');
      })
      .finally(() => {
        setAccessCode(null);
        setStatus(STATUS.IDLE);
        setModal(null);
      });
  }

  return (
    <>
      <UploadImageModal
        title="Current Banner"
        recommendedSize="a 3:1 height:width ratio (ex: 600 pixels wide by 200 pixels high) and less than 10mb."
        show={modal === modals.upload_image}
        onClose={() => setModal(null)}
        previewImageStyle={style.modalImagePreview}
        onUpload={(files) => handleBannerUpload(files)}
        preview={event.banner?.url}
        status={status}
      />
      {modal === modals.delete_event ? (
        <EventInfoModal
          event={event}
          mode={modal}
          onPressClose={() => setModal(null)}
        />
      ) : null}
      <EtherDialog
        header="Kiosk Link"
        subheader="Visit this link to open kiosk mode."
        controls={kioskShareControls}
        onClose={() => setModal(null)}
        show={modal === modals.kiosk_share}
        status={status}
      >
        <QRCode
          value={kioskLink}
          quietZone={20}
          size={250}
          color="black"
          backgroundColor={values.BGFIRST}
        />
        <Text style={style.kioskShareText}>{kioskLink}</Text>
      </EtherDialog>
      <EtherDialog
        header="Store Link"
        subheader="Visit this link to open the store."
        controls={storeShareControls}
        onClose={() => setModal(null)}
        show={modal === modals.store_share}
        status={status}
      >
        <QRCode
          value={storeLink}
          quietZone={20}
          size={250}
          color="black"
          backgroundColor={values.BGFIRST}
        />
        <Text style={style.kioskShareText}>{storeLink}</Text>
      </EtherDialog>

      <EtherDialog
        header="Remove Event Banner?"
        subheader="This will remove the banner from the current event."
        controls={removeBannerControls}
        onClose={() => setModal(null)}
        show={modal === modals.banner}
      />
      <EtherDialog
        header="Add Access Code"
        subheader="Customers will be asked to enter this code before purchasing items from this event."
        controls={accessCodeControls}
        onClose={() => setModal(null)}
        show={modal === modals.access_code}
        style={style.accessCodeModal}
      >
        <EtherInput
          placeholder="Access Code"
          value={accessCode}
          onChangeText={setAccessCode}
          onSubmitEditing={saveAccessCode}
          maxLength={32}
        />
      </EtherDialog>
      <EtherDialog
        header="Remove Access Code?"
        subheader="This will remove the access code from the current event."
        controls={removeAccessCodeControls}
        onClose={() => setModal(null)}
        show={modal === modals.remove_access_code}
        style={style.accessCodeModal}
      />
      <EtherDialog
        header="Rename Event"
        controls={renameEventControls}
        onClose={() => setModal(null)}
        show={modal === modals.rename_event}
        style={style.accessCodeModal}
      >
        <EtherInput
          title="Rename"
          placeholder={event.name}
          value={newEventName}
          onChangeText={setNewEventName}
          style={{marginBottom: 20}}
        />
      </EtherDialog>
      <TouchableOpacity
        style={[style.mainContainer, overrides]}
        onPress={onSelectEvent}
      >
        {dropdownId === event._id ? (
          <SimpleDropdown style={style.dropdown} entries={menuEntries} />
        ) : null}
        <View style={style.imageContainer}>
          <Image
            source={eventImage}
            style={
              event.banner?.url || event.sample?.key
                ? style.image
                : style.imagePlaceholder
            }
          />
          {!event?.active ? (
            <View style={style.showHide}>
              <FaIcon
                icon={event?.active ? faEye : faEyeSlash}
                size={50}
                color={values.FIRST}
                style={style.infoIcon}
              />
              <Text style={style.tinyText}>
                This event will be hidden from the store and kiosk
              </Text>
            </View>
          ) : null}
        </View>
        <View style={style.menuIconContainer}>
          <TouchableOpacity
            style={style.menuIconButton}
            dataSet={{dropdownId: event._id}}
            testID={`menu-${event.name}`}
          >
            <FaIcon
              icon={faEllipsisV}
              size={20}
              color={values.BGSECOND}
              // make click listeners fall through to parent
              style={[{pointerEvents: 'none'}, style.menuIcon]}
            />
          </TouchableOpacity>
        </View>
        <View
          style={style.infoContainer}
          onMouseLeave={() => setMouseHover(false)}
        >
          {mouseHover && event.name.length > 26 ? (
            <Text style={style.eventNameLong}>{event.name}</Text>
          ) : (
            <>
              <View
                style={style.infoTopBar}
                onMouseOver={() => setMouseHover(true)}
              >
                {mouseHover && event.name.length > 26 ? null : (
                  <Text style={style.eventName}>
                    {ellipsify(event.name, 26)}
                  </Text>
                )}
              </View>
              <View style={style.infoMidBar}>
                {event.location ? (
                  <View style={style.locationContainer}>
                    <FaIcon
                      icon={faMapMarkerAlt}
                      size={14}
                      color={values.FIRST}
                      style={style.infoIcon}
                    />
                    <Text style={style.tinyText}>
                      {ellipsify(event.location, 40)}
                    </Text>
                  </View>
                ) : null}
              </View>
            </>
          )}
        </View>
      </TouchableOpacity>
    </>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  accessCodeModal: {
    maxWidth: 400,
  },
  dropdown: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: theme.BGFIRST,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.FIRST,
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 30,
  },
  eventName: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 5,
  },
  eventNameLong: {
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    fontSize: fontSize.legal,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    backgroundColor: theme.BGFIRST,
    padding: 5,
  },
  fileCount: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 5,
  },
  image: {
    flex: 1,
  },
  imageContainer: {
    width: '100%',
    height: '68%',
    backgroundColor: theme.BGSECOND,
  },
  imagePlaceholder: {
    flex: 1,
    resizeMode: 'contain',
  },
  infoBotBar: {
    height: '33%',
    borderColor: theme.BGFIRST,
    borderTopWidth: 1,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoContainer: {
    backgroundColor: theme.BGFIRST,
    borderColor: theme.FIRST,
    borderTopWidth: 2,
    flex: 1,
    zIndex: 1,
  },
  infoIcon: {
    marginLeft: 5,
  },
  infoMidBar: {
    marginVertical: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoTopBar: {
    flexDirection: 'row',
    height: '33%',
    width: '100%',
  },
  kioskShareText: {
    fontSize: fontSize.body,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginBottom: 10,
    textAlign: 'center',
    cursor: 'text',
    maxWidth: 300,
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
    borderWidth: 2,
    borderColor: theme.FIRST,
    height: 220,
    width: 300,
    zIndex: -1,
  },
  menuIcon: {
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 1,
    textShadowColor: '#444444',
  },
  menuIconButton: {
    padding: 5,
  },
  menuIconContainer: {
    alignSelf: 'flex-end',
    flex: 1,
    position: 'absolute',
    backgroundColor: theme.SECOND,
  },
  modalImagePreview: {
    resizeMode: 'center',
  },
  showHide: {
    alignItems: 'center',
    marginTop: 40,
    width: '100%',
    position: 'absolute',
    backgroundColor: transparentize(0.2, theme.YELLOW),
    padding: 10,
    flexDirection: 'column',
  },
  tinyText: {
    fontSize: fontSize.tiny,
    fontFamily: 'NotoSans_Regular',
    color: theme.DARK,
    marginLeft: 5,
  },
});
