import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import {useIsFocused} from '@react-navigation/native';
import React, {useState, useRef, useEffect} from 'react';
import toast from 'react-hot-toast';
import {
  FlatList,
  Modal,
  Text,
  View,
  Image,
  Keyboard,
  ScrollView,
  ActivityIndicator,
} from 'react-native';

import EMLogoFallbackPNG from '../../assets/EtherMediaIcon.png';
import DirList from '../../components/DirList';
import EtherButton from '../../components/EtherButton';
import FaIcon from '../../components/FaIcon';
import GalleryTile from '../../components/GalleryTile';
import IdleTimer from '../../components/IdleTimer';
import ImageViewerComponent from '../../components/ImageViewer';
import StoreAccessCodePrompt from '../../components/StoreAccessCodePrompt';
import {useCart} from '../../context/CartContext';
import {useTheme} from '../../context/ThemeContext';
import {SELECT, HEADER_HEIGHT_KIOSK} from '../../utils/common/constants';
import {useResizeCb, useWindowDimensions} from '../../utils/common/hooks';
import {getSafeAssets, getSafeEventWithPacks} from '../../utils/common/stores';
import etherFetch from '../../utils/ether-fetch/etherFetch';

const TILE_SIZE = 190;
const TILE_SPACING = 13;
const CONTAINER_PERCENT = 0.74;

export default function Kiosk({navigation: {navigate}, route}) {
  const galleryRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [assets, setAssets] = useState([]);
  const [packs, setPacks] = useState([]);
  const [event, setEvent] = useState(null);
  const [store, setStore] = useState(null);
  const [currentPack, setCurrentPack] = useState(null);
  const [selectable, setSelectable] = useState(SELECT.DEFAULT);
  const {cart, addItem, toggleSelect, clearCart} = useCart();
  const {style, values} = useTheme(getThemedStyles);
  const {width} = useWindowDimensions();
  const innerHeight = useResizeCb(() => window.innerHeight);
  const [accessCode, setAccessCode] = useState('');
  const [hasAccess, setHasAccess] = useState(false);
  const isFocused = useIsFocused();
  const showCount = cart.length > 0;

  const {storeSlug, eventId} = route.params;

  // Calculate tile measurements
  const containerWidth = width * CONTAINER_PERCENT;
  const tileTotalWidth = TILE_SIZE + TILE_SPACING;
  const numColumns = Math.floor(containerWidth / tileTotalWidth);
  const remainder = containerWidth % tileTotalWidth;
  const remainderDivided = remainder / numColumns;

  useEffect(() => {
    async function loadStore() {
      setLoaded(false);
      try {
        const eventRes = await getSafeEventWithPacks(storeSlug, eventId);
        setPacks(eventRes.packs);
        setCurrentPack(eventRes.packs[0]);
        setEvent(eventRes.event);
        setStore(eventRes.store);
        setHasAccess(eventRes.hasAccess);
        localStorage.setItem('storeName', eventRes.store.orgname);
        localStorage.setItem('eventName', eventRes.event.name);
        localStorage.setItem('kioskUrl', window.location.href);
        localStorage.setItem('storeSlug', storeSlug);
      } catch (error) {
        console.error('Error loading kiosk:', error);
        toast.error('Error loading kiosk. Please contact support.');
      }
      setLoaded(true);
    }

    if (storeSlug && eventId) {
      loadStore();
    }
  }, [storeSlug, eventId]);

  useEffect(() => {
    async function loadAssets(packName) {
      const assetRes = await getSafeAssets(packName, eventId);
      const sortedAssets = [...assetRes.assets].sort((a, b) => {
        if (a.favorite && !b.favorite) return -1;
        if (!a.favorite && b.favorite) return 1;
        return 0;
      });

      setAssets(sortedAssets);
    }

    if (currentPack) {
      loadAssets(currentPack.name);
    }
  }, [currentPack, eventId]);

  function goToCart() {
    if (!showCount) {
      addPack();
    }
    navigate('Cart');
  }

  function addPack() {
    addItem(
      {
        name: currentPack.name,
        packId: currentPack.id,
        event: event.name,
        assets,
      },
      'kiosk',
    );
  }

  function closeImageViewer() {
    setSelectedIndex(null);
  }

  function changeSelectedPack(newPack) {
    setCurrentPack(newPack);
    Keyboard.dismiss();
  }

  async function onConfirm() {
    if (!accessCode) return;

    try {
      await etherFetch('/store/submit-access-code', {
        method: 'POST',
        body: {eventId, accessCode},
      });

      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  }

  const videoWatermarkSrc = store?.watermark || EMLogoFallbackPNG;

  if (!loaded) {
    return (
      <ScrollView contentContainerStyle={style.loadingContainer}>
        <ActivityIndicator
          size={34}
          color={values.FIRST}
          style={{marginBottom: 40}}
        />
        <Text style={style.loadingMessage}>Loading...</Text>
      </ScrollView>
    );
  }

  if (!hasAccess) {
    return (
      <StoreAccessCodePrompt
        storeInfo={store}
        eventInfo={event}
        accessCode={accessCode}
        setAccessCode={setAccessCode}
        onConfirm={onConfirm}
      />
    );
  }

  return (
    <IdleTimer active={isFocused && cart.length > 0} onTimeout={clearCart}>
      {/* Add this css incantation from SO to prevent spawning extra space at the bottom */}
      {/* https://stackoverflow.com/a/12046471 */}
      {isFocused ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `
html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body > div {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
`,
          }}
        />
      ) : null}
      <View
        style={[
          style.masterContainer,
          {
            height: `calc(${innerHeight}px - ${HEADER_HEIGHT_KIOSK}px)`,
          },
        ]}
      >
        <View style={style.dirListContainer}>
          <DirList
            msgHeader="No Packs Found"
            style={style.galleryDirList}
            searchStyle={style.searchStyle}
            dirs={packs}
            onPress={(pack) => changeSelectedPack(pack)}
            selected={currentPack?.name}
          />
        </View>
        <View style={style.controlContainer}>
          <View style={style.controlContainerLeft}>
            <EtherButton
              style={style.addToCartButton}
              onPress={addPack}
              disabled={assets.length < 1}
            >
              <Text style={style.addToCartButtonText}>+ Add pack to cart</Text>
            </EtherButton>
          </View>
          <View style={style.controlContainerRight}>
            <EtherButton
              style={style.continueToCartButton}
              onPress={goToCart}
              disabled={assets.length < 1}
            >
              <View style={style.cartButton}>
                <FaIcon
                  style={style.cartButtonIcon}
                  icon={faShoppingCart}
                  size={30}
                />
                {showCount ? (
                  <View style={style.cartCounterContainer}>
                    <Text
                      style={style.cartCounterText}
                    >{`${cart.length}`}</Text>
                  </View>
                ) : null}
              </View>
              <Text style={style.addToCartButtonText}>
                {showCount ? 'Continue to Cart' : 'Buy Now'}
              </Text>
            </EtherButton>
          </View>
        </View>
        <View style={style.assetsBackground}>
          <Image
            resizeMode="contain"
            source={store?.logo}
            style={[style.logoBackground, {top: '10%'}]}
          />
          <FlatList
            refreshing={!loaded}
            data={assets}
            ref={galleryRef}
            keyExtractor={(asset) => asset.name}
            key={numColumns}
            numColumns={numColumns}
            ListEmptyComponent={
              <View style={{alignItems: 'center'}}>
                <Text style={style.noImages}>This pack is empty</Text>
                <Text style={style.noImagesSub}>
                  Go to ethermedia.app to upload content in the Asset Manager
                </Text>
              </View>
            }
            ListFooterComponent={<View style={{height: 100}} />}
            showsVerticalScrollIndicator={false}
            renderItem={({
              item,
              item: {name, thumb, mime, duration},
              index,
            }) => {
              return (
                <GalleryTile
                  width={TILE_SIZE + remainderDivided}
                  height={TILE_SIZE + remainderDivided}
                  source={thumb.url}
                  index={index}
                  mime={mime}
                  duration={duration}
                  onPress={() => setSelectedIndex(index)}
                  onLongPress={() => {
                    setSelectable(
                      selectable === SELECT.DEFAULT
                        ? SELECT.SELECTABLE
                        : SELECT.DEFAULT,
                    );
                  }}
                  onSelectPress={() => toggleSelect(item)}
                  selected={
                    cart.find((itm) => itm.name === name)
                      ? SELECT.SELECTED
                      : selectable
                  }
                  testID="kiosk-gallery-tile"
                />
              );
            }}
          />
        </View>
        <Modal
          visible={!!selectedIndex || selectedIndex === 0}
          transparent
          ariaHideApp={false}
        >
          <ImageViewerComponent
            onClose={closeImageViewer}
            assets={assets}
            videoWatermarkSrc={videoWatermarkSrc}
            currentIndex={selectedIndex}
            setIndex={setSelectedIndex}
          />
        </Modal>
      </View>
    </IdleTimer>
  );
}

const getThemedStyles = (theme, fontSize) => ({
  addToCartButton: {
    width: '100%',
    height: 60,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addToCartButtonText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.header,
    color: theme.LIGHT,
  },
  assetsBackground: {
    width: '100%',
    flex: 1,
  },
  cartButton: {
    marginRight: 10,
  },
  cartButtonIcon: {
    color: theme.LIGHT,
  },
  cartCounterContainer: {
    right: -6,
    position: 'absolute',
    backgroundColor: theme.RED,
    height: 20,
    width: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  cartCounterText: {
    fontFamily: 'NotoSans_Bold',
    fontSize: fontSize.tiny,
    color: theme.LIGHT,
  },
  closePreview: {
    position: 'absolute',
    padding: 10,
    right: 0,
    top: 0,
  },
  continueToCartButton: {
    width: '100%',
    height: 60,
    borderRadius: 5,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  controlContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    flexDirection: 'row',
    width: '75%',
    left: '25%',
    justifyContent: 'space-between',
    zIndex: 9999,
  },
  controlContainerLeft: {
    backgroundColor: theme.LIGHT,
    padding: 16,
    borderTopRightRadius: 20,
  },
  controlContainerRight: {
    backgroundColor: theme.LIGHT,
    padding: 16,
    borderTopLeftRadius: 20,
  },
  dirListContainer: {
    width: '25%',
    height: 'auto',
    backgroundColor: theme.FIRST,
  },
  galleryDirList: {
    height: '100%',
  },
  imageViewer: {
    backgroundColor: theme.DARK,
    justifyContent: 'center',
    fontSize: 28,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  imageViewerText: {
    fontSize: 28,
    fontFamily: 'NotoSans_Bold',
    color: theme.LIGHT,
  },
  imageViewerTextContainer: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  loadingMessage: {
    textAlign: 'center',
  },
  logoBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: 1000,
    maxHeight: 1000,
    alignSelf: 'center',
    opacity: 0.2,
  },
  masterContainer: {
    flexDirection: 'row',
    backgroundColor: theme.BGSECOND,
  },
  mutedText: {
    zIndex: 9999,
    position: 'absolute',
    top: '90%',
    alignSelf: 'center',
    fontSize: 18,
    fontFamily: 'NotoSans_Bold',
    letterSpacing: 6,
    color: theme.LIGHT,
    textShadowColor: theme.DARK,
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 0.1,
  },
  noImages: {
    color: theme.DARK,
    fontSize: 36,
    fontFamily: 'NotoSans_Bold',
    marginTop: 250,
    marginBottom: 20,
  },
  noImagesSub: {
    color: theme.DARK,
    fontSize: 18,
    fontFamily: 'NotoSans_Regular',
  },
  previewText: {
    zIndex: 9999,
    position: 'absolute',
    top: '95%',
    alignSelf: 'center',
    fontSize: 18,
    fontFamily: 'NotoSans_Bold',
    letterSpacing: 6,
    color: theme.LIGHT,
    textShadowColor: theme.DARK,
    textShadowOffset: {width: 2, height: 2},
    textShadowRadius: 0.1,
  },
  searchStyle: {
    borderRadius: 10,
    margin: 10,
    height: 40,
    width: '95%',
    alignSelf: 'center',
  },
});
